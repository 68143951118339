import React, { useEffect, useContext, useState } from 'react'
import { withStyles, Grid, Button, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { getAssessmentParts } from 'efqm-theme/assessments/getAssessmentParts'
import {
  useAuthorizedQuery,
  getQuestionnaireChartData,
  BarChartTable,
  PaddedContainer,
  SectionTitle,
  AuthContext,
  RichText,
} from 'gatsby-components'
import { Link } from 'gatsby'
import get from 'lodash/get'
import { Redirect } from '@reach/router'

import SEO from '../components/SEO'
import { getAssessmentId } from '../utils/url'
import { filterOldScores } from '../utils/filter-old-scores'
import { getQuestionnaireFeedbackReportData } from '../queries'
import Questionnaire2020DataContext from '../components/Questionnaire2020DataContext'

function QuestionnaireFeedbackReport({
  theme,
  classes,
  pageContext: { assessment: contextAssessment, pillarColors },
  location,
}) {
  const { t, i18n } = useTranslation()
  const { assessment } = getAssessmentParts(contextAssessment.key, i18n)
  const assessmentId = getAssessmentId(location)

  const {
    getContextDataForChart,
    reloadQuestionnaire,
    getSavedNonUserQuestionnaireData,
  } = useContext(Questionnaire2020DataContext)

  const { isAuthInitialized, saveQuestionnaireToDB } = useContext(AuthContext)
  const [emailAddressForAdminView, setEmailAddressForAdminView] = useState(null)

  useEffect(() => {
    if (assessmentId === null) {
      const url = new URL(window.location.href)
      const email = url.searchParams.get('email')
      const key = url.searchParams.get('key')
      if (email && key) {
        if (isAuthInitialized) {
          // the user is trying to load saved questionnaire data from non-users
          getSavedNonUserQuestionnaireData(key, email)
          setEmailAddressForAdminView(email)
        }
      } else {
        // the user is trying to view their own locally saved non-user data
        reloadQuestionnaire(contextAssessment.key)
      }
    }
  }, [isAuthInitialized])

  const { data: dataFromDb } = useAuthorizedQuery(
    getQuestionnaireFeedbackReportData,
    { assessmentId },
    {
      onPreFetch: variables => !!variables.assessmentId,
      onFetch: data => filterOldScores(assessment, data.assessment_by_pk),
    }
  )

  if (
    isAuthInitialized &&
    assessmentId &&
    !saveQuestionnaireToDB(assessmentId)
  ) {
    return <Redirect to="/auth" noThrow />
  }

  const assessmentData =
    assessmentId !== null
      ? dataFromDb
      : getContextDataForChart(contextAssessment.key)

  let chartData
  if (assessmentData) {
    chartData = getQuestionnaireChartData(
      assessment,
      assessmentData,
      pillarColors
    )
  }

  const assessmentName = get(assessmentData, 'name', t('Loading...'))

  return (
    <div className={classes.root}>
      <SEO title={assessment.name} />
      <PaddedContainer className={classes.paddedContainer}>
        <Grid container justify="space-between">
          <Grid item>
            <Button
              component={Link}
              to={`/assessment/${assessment.key}#${assessmentId}`}
              variant="text"
              color="secondary"
            >
              ◀ {t('Assessment overview')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => window.print()}
            >
              {t('Print Report')}
            </Button>
          </Grid>
        </Grid>
        <div className={classes.section}>
          <Typography variant="h4" gutterBottom>
            {t('feedback report')}
          </Typography>
          <Typography variant="h2" color="primary">
            {assessmentName}
          </Typography>
          {emailAddressForAdminView && (
            <Typography variant="body2" className={classes.email}>
              <a href={`mailto:${emailAddressForAdminView}`}>
                {emailAddressForAdminView}
              </a>
            </Typography>
          )}
        </div>
        <div className={classes.section}>
          <Grid container spacing={5}>
            <Grid item xs={3}>
              <SectionTitle barColor={theme.palette.primary.dark} gutterBottom>
                {t(`Questionnaire scoring summary`)}
              </SectionTitle>
              <br />
              <Typography variant="body2">
                {t(
                  `Click on the chart bar for Direction/Execution/Results to view the score by question.`
                )}
              </Typography>
              <br />
              <Typography variant="body2">
                {t(
                  `The weighted score is calculated on a maximum of 100 points, distributed to Direction for 20 points, Execution and Results, each for 40 points. This is in line with the scoring matrix for the EFQM Model.`
                )}
              </Typography>
            </Grid>
            <Grid item xs>
              {assessmentData && (
                <BarChartTable
                  chartData={chartData}
                  assessmentId={assessmentId}
                  canExpand={true}
                />
              )}
            </Grid>
          </Grid>
        </div>
        <div className="page-break" />
        <div className={classes.section}>
          {assessmentData &&
            assessment.pillars.map((pillarDef, pillarIndex) => {
              const tables = assessmentData.questionnaire_tables
                .filter(table => table.pillar_key === pillarDef.key)
                .sort((a, b) => a.user_id - b.user_id)
              if (!tables.length) return null

              return (
                <React.Fragment key={pillarDef.key}>
                  {!!pillarIndex && <div className="page-break" />}
                  <Grid
                    container
                    spacing={4}
                    direction="column"
                    className={classes.rationaleContainer}
                  >
                    <Grid key={pillarDef.key} item container spacing={2}>
                      <Grid item xs={3}>
                        <SectionTitle barColor={pillarColors[pillarIndex]}>
                          <Link
                            to={`/assessment/${assessmentData.key}#${assessmentId}`}
                          >
                            {pillarDef.name}
                          </Link>
                        </SectionTitle>
                      </Grid>
                      {tables.map((table, i) => {
                        const rationale = get(
                          table,
                          'table_values[0].background-info'
                        )
                        if (!rationale) return null

                        return (
                          <React.Fragment
                            key={`${pillarDef.key}rationale_${i}`}
                          >
                            {!!i && <Grid item xs={3}></Grid>}
                            <Grid item xs={9}>
                              <Typography
                                variant="h4"
                                className={classes.rationaleHeader}
                              >
                                {table.user
                                  ? `${t('rationale')} - ${
                                      table.user.first_name
                                    } ${table.user.last_name}`
                                  : 'rationale'}
                              </Typography>
                              <RichText value={rationale} />
                            </Grid>
                          </React.Fragment>
                        )
                      })}
                    </Grid>
                  </Grid>
                </React.Fragment>
              )
            })}
        </div>
      </PaddedContainer>
    </div>
  )
}

const styles = theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  paddedContainer: {
    flex: 1,
  },
  section: {
    marginTop: theme.spacing(3),
  },
  rationaleContainer: {
    marginBottom: theme.spacing(4),
  },
  rationaleHeader: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(-0.25),
  },
  email: {
    marginTop: theme.spacing(0.5),
    '& > a': {
      color: theme.palette.primary.dark,
    },
  },
  '@media print': {
    '@global': {
      footer: {
        display: 'none',
      },
      '.page-break': { display: 'block', pageBreakBefore: 'always' },
    },
  },
  '@media all': {
    '.page-break': { display: 'none' },
  },
})

export default withStyles(styles, { withTheme: true })(
  QuestionnaireFeedbackReport
)
